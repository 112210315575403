.title {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 8px;
    margin-left: 24px;
    width: 500px;
}

.title2 {
    width: 165px;
    font-size: 16px;
    font-weight: 700;
}

.sub-title {
    font-size: 13px;
    font-weight: 700;
}

.info-font-style {
    font-weight: 400;
    font-size: 14px;
    color: #2176f6;
    margin-top: 12px;
}
