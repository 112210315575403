$defaultColor: #C4C4C4;
$activeColor: #2176F6;
$alertColor: #2176F6;

@mixin select-border-color($color) {
  border-color: $color;
}

.select {
  border: 1px solid $defaultColor;
  border-radius: 8px;
  font-size: 12px;

  &.unospay {
    width: 100px;
    height: 25px;
  }

  &.small {
    width: 108px;
    height: 36px;
    padding: 5px;
  }

  &.x-middle {
    width: 244px;
    height: 36px;
  }

  &.middle {
    width: 352px;
    height: 36px;
    padding: 5px;
  }

  &.large {
    width: 472px;
    height: 36px;
    padding: 5px;
  }


  &:active {
    border: 1px solid $activeColor;
  }

  &.grey {
    @include select-border-color($defaultColor)
  }

  &.red {
    @include select-border-color($alertColor);
  }

  &.blue {
    @include select-border-color($activeColor);
  }
}
