$defaultColor: #C4C4C4;
$activeColor: #2176F6;
$alertColor: #2176F6;
$none: #FFFFFF;

@mixin input-border-color($color) {
  border-color: $color;
}

.TextArea {
  border: 1px solid $defaultColor;
  border-radius: 8px;
  resize: none;
  overflow: auto;
  width: 472px;
  height: 92px;
  margin-bottom: 12px;
  background: #FFFFFF;
  padding: 1%;
  font-size: 13px;

  &:active {
    border: 1px solid $activeColor;
  }

  &.grey {
    @include input-border-color($defaultColor)
  }

  &.white {
    @include input-border-color($none)
  }

  &.red {
    @include input-border-color($alertColor);
  }

  &.blue {
    @include input-border-color($activeColor);
  }

  &.none {
    @include input-border-color($none);
  }

  &.middle {
    width: 472px;
    height: 92px;
  }

  &.large {
    width: 976px;
    height: 80px;
    font-weight: 500;
    font-size: 12px;
  }

  &.xLarge {
    width: 976px;
    height: 140px;
    font-weight: 500;
    font-size: 12px;
  }

  &.auto {
    width: 976px;
    // height: auto;
    font-weight: 500;
    font-size: 12px;
  }

}