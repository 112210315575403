$defaultColor: #c4c4c4;
$activeColor: #2176f6;
$alertColor: #2176f6;
$whiteColor: #ffffff;

@mixin input-border-color($color) {
    border-color: $color;
}

/* 툴팁 스타일 */

.tooltip {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.7);
    border: 1px solid rgba(0, 0, 0, 0.7);
    color: white;
    padding: 8px 12px;
    z-index: 9999;
    border-radius: 5px;
    white-space: nowrap;
    display: block;
    height: 36px;
    font-size: 12px;
    top: 0;
    bottom: auto;
}

.tooltip-text {
    overflow: visible;
}

.Input[type="text"] {
    border: 1px solid $defaultColor;
    border-radius: 8px;
    font-size: 12px;
    background: $whiteColor;
    color: #000000;

    &.unosInput {
        width: 90px;
        height: 25px;
        padding-left: 5px;
    }

    &.transport {
        width: 141px;
        height: 25px;
        padding-left: 5px;
    }

    &.transportMemo {
        width: 244px;
        height: 36px;
        padding: 5px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        background-color: white;

        &:hover {
            text-overflow: ellipsis;
            overflow: initial;
            white-space: initial;
        }

        &:hover+.tooltip {
            display: block;
        }
    }



    &.trackingNumber {
        width: 250px;
        height: 25px;
        padding-left: 5px;
    }

    &.xSmall {
        width: 108px;
        height: 36px;
        padding: 3%;
    }

    &.small {
        width: 212px;
        height: 44px;
        text-align: right;
        padding-right: 5px;
    }

    &.xMiddle {
        width: 244px;
        height: 36px;
        padding: 5px;
    }

    &.middle {
        width: 352px;
        height: 36px;
        padding-left: 8px;
    }

    &.large {
        width: 472px;
        height: 36px;
        padding: 5px;
    }

    &.xLarge {
        width: 868px;
        height: 36px;
        padding: 8px;
    }

    &:active {
        border: 1px solid $activeColor;
    }

    &:focus {
        border: 1px solid $activeColor;
    }

    &.grey {
        @include input-border-color($defaultColor);
    }

    &.red {
        @include input-border-color($alertColor);
    }

    &.blue {
        @include input-border-color($activeColor);
    }

    &.white {
        @include input-border-color($whiteColor);
    }
}