.container {
  max-width: 1000px;
  padding-top: 100px;

}

.active-tab-button {
  background: #EAEAEA;
  border-radius: 14px 14px 0 0;
  border: 1px solid #C4C4C4;
  width: 148px;
  height: 48px;
  border-bottom: #536DA3;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0;
  margin-right: 4px;
  cursor: pointer;

  &:focus {
    background: #EAEAEA;
    margin-right: 4px;
    box-shadow: none;
  }

  &:hover {
    margin-right: 4px;
    background: #FFFFFF;
    border: 1px solid #C4C4C4;
  }
}


.btn-check {
  display: none;
}

.btn-check:checked + .active-tab-button {
  background: #FFFFFF;
  border: 1px solid #C4C4C4;
  color: #536DA3;
  border-radius: 14px 14px 0 0;
  width: 148px;
  height: 48px;
  border-bottom: #536DA3;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0;
  margin-right: 4px;

  & > .active-tab-button-label span {
    background: #536DA3;
  }

  & > .active-tab-border-div {
    background: #536DA3;
  }
}

.btn-check + .active-tab-button {
  background: #EAEAEA;
  border: 1px solid #C4C4C4;
  color: #C4C4C4;
  border-radius: 14px 14px 0 0;
  width: 148px;
  height: 48px;
  border-bottom: #536DA3;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0;
  margin-right: 4px;

  & > .active-tab-button-label span {
    background: #C4C4C4;
  }

  & > .active-tab-border-div {
    background: #C4C4C4;
  }

}

.active-tab-button-label {
  height: 48px;
  width: 148px;
  display: flex;
  justify-content: flex-start;
  line-height: 24px;
  font-weight: 700;
  padding-left: 20px;
  padding-right: 16px;
  padding-top: 12px;
  cursor: pointer;

  & > span {
    background: #536DA3;
    border-radius: 50%;
    color: #FFFFFF;
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }
}

.active-tab-border-div {
  background: #536DA3;
  height: 4px;
  width: 100%;
}

// 의사
.doctor-active-tab-button {
  background: #EAEAEA;
  border: 1px solid #C4C4C4;
  border-radius: 14px 14px 0 0;
  width: auto;
  height: 48px;
  border-bottom: #536DA3;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0;
  margin-right: 4px;

  &:focus {
    background: #EAEAEA;
    margin-right: 4px;
    box-shadow: none;
  }

  &:hover {
    margin-right: 4px;
    background: #FFFFFF;
    border: 1px solid #C4C4C4;
  }
}

.btn-check:checked + .doctor-active-tab-button {
  background: #FFFFFF;
  border: 1px solid #C4C4C4;
  border-radius: 14px 14px 0 0;
  width: auto;
  height: 48px;
  border-bottom: #536DA3;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 4px;
}

.doctor-active-tab-button-label {
  height: 90%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #536DA3;
  line-height: 24px;
  font-weight: 700;
}