.table {
  border-top: 2px solid #C8D6F8;
  border-bottom: 1px solid #C8D6F8;
  width: auto;
  height: auto;
  font-size: 13px;
  margin-top: 8px;

  tr {
    border-bottom: 1px solid #C8D6F8;
    height: 50px;
  }

  td:nth-child(2n-1) {
    background: #F4F6FB;
    width: 100px;
    text-align: center;
  }

  td:nth-child(2n) {
    font-weight: normal;
    padding-left: 8px;
    font-size: 13px;
  }
}