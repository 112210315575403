.container-div {
    margin-top: 48px;
}

.container-div table {
    margin-top: 8px;
    border-top: 2px solid #c8d6f8;
    border-bottom: 2px solid #c8d6f8;
    width: 100%;
    height: auto;
}

.container-div table tr:nth-child(1) th {
    width: 100%;
    height: 38px;
    border-bottom: 1px solid #c8d6f8;
    background: #f4f6fb;
}

.container-div table tr:nth-child(1) th div {
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 13px;
    margin-left: 14px;
    color: #030303;
}

.filesContainer-div {
    height: 100%;
    overflow: auto;
}

.filesContents-div {
    display: flex;
    width: 100%;
    height: 40px;
}

.filesContents-div:hover {
    background-color: #f8f8f8;
}

.filesName-div {
    display: flex;
    width: 90%;
    height: auto;
    align-items: center;
}

.cancelBtn-div {
    display: flex;
    width: 100%;
    height: auto;
    justify-content: flex-end;
}

.cancelBtn-div button {
    background: none;
    border: none;
}

.custom-cloud-icon {
    color: #1c6fc9;
    width: 24px;
    height: 16px;
    padding-right: 8px;
}

.custom-cancel-icon {
    color: #c4c4c4;
    width: 16px;
    height: 16px;
}

.custom-pdf-icon {
    width: 24px;
    height: 24px;
    color: gray;
}

input[type='file'] {
    position: absolute;
    width: 0;
    height: 0;
    padding: 0;
    overflow: hidden;
    border: 0;
}

.upload-label {
    display: flex;
    border: 1px solid #d3d9e4;
    width: 160px;
    height: 40px;
    border-radius: 8px;
    align-items: center;
    justify-content: center;
}

.upload-label div {
    font-weight: 700;
    font-size: 13px;
}

.prescriptionLink-a {
    color: #567fe7;
    font-weight: 400;
    font-size: 13px;
    padding-left: 8px;
}

.custom-arrow-icon {
    color: #ffffff;
}

.custom-close-icon {
    width: 30px;
    height: 30px;
}
