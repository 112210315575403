$defaultColor: #C4C4C4;
$activeColor: #2176F6;
$alertColor: #2176F6;

.time-table-head {
  display: flex;
  background: #F4F6FB;
  border-top: 2px solid #C8D6F8;
  border-bottom: 1px solid #C8D6F8;
  font-weight: 700;
  font-size: 13px;

  & > div:nth-child(1) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 158px;
    height: 48px;
  }

  & > div:nth-child(2) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 324px;
    height: 48px;
    border-left: 1px solid #C8D6F8;
    border-right: 1px solid #C8D6F8;
  }

  & > div:nth-child(3) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 324px;
    height: 48px;
    border-right: 1px solid #C8D6F8;
  }

  & > div:nth-child(4) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 158px;
    height: 48px;
  }
}

.time-table {
  width: 158px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #C8D6F8;
  font-size: 13px;


  &.middle {
    width: 324px;
    height: 48px;
    border-left: 1px solid #C8D6F8;
  }

  &.openHours {
    width: 324px;
    height: 48px;
    border-left: 1px solid #C8D6F8;
    border-right: 1px solid #C8D6F8;
  }

  &.closeHours {
    width: 324px;
    height: 48px;
    border-right: 1px solid #C8D6F8;
  }
}

.time-divide {
  padding-left: 12px;
  padding-right: 12px;
  font-weight: 700;
}

@mixin select-border-color($color) {
  border-color: $color;
}

.select {
  width: 72px;
  height: 36px;
  border: 1px solid $defaultColor;
  border-radius: 8px;
  text-align: center;

  &:active {
    border: 1px solid $activeColor;
  }

  &.grey {
    @include select-border-color($defaultColor)
  }

  &.red {
    @include select-border-color($alertColor);
  }

  &.blue {
    @include select-border-color($activeColor);
  }
}