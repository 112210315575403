$activeColor: #2176F6;
$defaultColor: #C2C1BD;

@mixin button-border-color($color) {
  border-color: $color;
  color: $color;
}

/* 상태 버튼 */
.button {
  width: 140px;
  height: 40px;
  font-weight: 700;
  font-size: 13px;
  background: #FFF;
  border: 1px solid #C2C1BD;
  border-radius: 8px;
  color: #000000;
  margin-left: 10px;
}

.wideButton {
  width: 156px;
  height: 40px;
  font-weight: 700;
  font-size: 13px;
  background: #FFF;
  border: 1px solid #C2C1BD;
  border-radius: 8px;
  color: #000000;
  margin-left: 10px;
}

.buttons-div {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
}

.color-button {
  width: 100px;
  height: 40px;
  font-weight: 700;
  font-size: 13px;
  background: #EFF7FF;
  border: 1px solid #2176F6;
  border-radius: 8px;
  color: #2176F6;
  margin-left: 10px;
}

.reservation-button {
  width: 80px;
  height: 24px;
  background: #6E8ED1;
  border-radius: 4px;
  color: #FFFFFF;
  border: none;
  font-weight: 500;
  font-size: 12px;
}

.not-reservation-button {
  width: 80px;
  height: 24px;
  background: #00326A;
  border-radius: 4px;
  color: #FFFFFF;
  border: none;
  font-weight: 500;
  font-size: 12px;
}

.waiting-button {
  width: 71px;
  height: 36px;
  background: #FFFFFF;
  border: 1px solid $defaultColor;
  border-radius: 8px;
  color: $defaultColor;

  &.grey {
    @include button-border-color($defaultColor)
  }

  &.blue {
    @include button-border-color($activeColor);
  }
}

.diagnosis-submit-button {
  width: 360px;
  height: 60px;
  background: #6E8ED1;
  border: none;
  border-radius: 15px;
  color: #FFFFFF;
  cursor: pointer;

  &:hover {
    background: #6E8ED1;
    box-shadow: none;
  }

  &:focus {
    background: #6E8ED1;
    border-color: #FFFFFF;
    box-shadow: none;
  }

  &:active {
    background: #6E8ED1;
    box-shadow: none;
  }

  &:disabled {
    background: #FFFFFF;
    color: gray;
    border: 1px solid #C2C1BD;
    cursor: default;
  }
}

.diagnosis-cancel-button {
  width: 360px;
  height: 60px;
  background: #CF5D5D;
  border: none;
  border-radius: 15px;
  color: #FFFFFF;
  margin-right: 20px;

  &:hover {
    background: #CF5D5D;
    box-shadow: none;
  }

  &:focus {
    background: #CF5D5D;
    border-color: #FFFFFF;
    box-shadow: none;
  }

  &:active {
    background: #CF5D5D;
    box-shadow: none;
  }
}

.diagnosis-save-button {
  width: 360px;
  height: 60px;
  background: #FFFFFF;
  border: 1px solid #C2C1BD;
  border-radius: 15px;
  color: #000000;
  margin-right: 20px;
  cursor: pointer;

  &:hover {
    background: #6E8ED1;
    box-shadow: none;
    color: #FFFFFF;
  }

  &:focus {
    background: #6E8ED1;
    border-color: #FFFFFF;
    box-shadow: none;
  }

  &:active {
    background: #6E8ED1;
    box-shadow: none;
  }

  &:disabled {
    background: #FFFFFF;
    color: gray;
    cursor: default;
  }
}

.blue-button {
  width: 120px;
  height: 36px;
  background: #EFF7FF;
  border: 1px solid #2176F7;
  border-radius: 8px;
  color: #2176F6;
  font-size: 13px;
  font-weight: 700;

  &:disabled {
    background: #E9E9E9;
    border: 1px solid #9D9C9C;
    color: #9D9C9C;
  }
}

.cancel-button {
  width: 120px;
  height: 36px;
  background: #FDEFEF;
  border: 1px solid #EC2C2C;
  border-radius: 8px;
  color: #EC2C2C;
  font-size: 13px;
  font-weight: 700;

  &:disabled {
    background: #E9E9E9;
    border: 1px solid #9D9C9C;
    color: #9D9C9C;
  }
}

//체크박스 버튼
.checkbox-label {
  cursor: pointer;
  width: 148px;
  height: 42px;
  background: #FFFFFF;
  border: 1px solid #D3D9E4;
  border-radius: 8px;
  color: #CBCBCB;
  font-size: 13px;
  font-weight: 700;
  margin-left: 8px;
  margin-right: 16px;
  margin-bottom: 8px;

  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
}

input[type=checkbox]:checked + .checkbox-label {
  background: #EFF7FF;
  border: 1px solid #2176F7;
  color: #2176F6;
  margin-left: 8px;
  margin-right: 16px;
  margin-bottom: 8px;
}


.tab-button {
  background: #FFFFFF;
  border-radius: 14px 14px 0 0;
  border: 1px solid #C4C4C4;
  width: 148px;
  height: 48px;
  border-bottom: #536DA3;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0;
  margin-right: 4px;
  cursor: pointer;

  & > div {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #536DA3;
    font-weight: 700;
  }
}

// 알림톡 전송 버튼
.alimtalk-button {
  padding: 5px 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #ffffff;
  cursor: pointer;
  font-size: 12px;
  
  &:hover:not(:disabled) {
    background-color: #f5f5f5;
  }
  
  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
  
  &.sending {
    background-color: #f0f0f0;
    cursor: not-allowed;
  }
  
  &.sent {
    background-color: #cccccc;
    cursor: not-allowed;
  }
}


