$hoverColor: #f8f8f8;

/* 파일이 있는 경우 */
.container-div {
    margin-top: 48px;

    & > table {
        margin-top: 8px;
        border-top: 2px solid #c8d6f8;
        border-bottom: 1px solid #c8d6f8;
        width: 100%;
        height: auto;
        font-size: 13px;
        font-weight: 700;

        & tr {
            th {
                width: 100%;
                height: 38px;
                border-bottom: 1px solid #c8d6f8;
                background: #f4f6fb;

                & > div {
                    display: flex;
                    align-items: center;
                    font-weight: 700;
                    font-size: 13px;
                    margin-left: 14px;
                    color: #030303;
                }
            }
        }
    }
}

.filesContainer-div {
    height: 100%;
    overflow: auto;
}

.filesContents-div {
    display: flex;
    width: 100%;
    height: 40px;
    &:hover {
        background-color: #f8f8f8;
    }
}

.filesContainer-checked-div {
    display: flex;
    width: 100%;
    height: 40px;
    background: $hoverColor;
}

.filesName-div {
    display: flex;
    width: 90%;
    height: auto;
    align-items: center;
    padding-left: 15px;
}

.cancelBtn-div {
    display: flex;
    width: 100%;
    height: auto;
    justify-content: flex-end;

    & > button {
        background: none;
        border: none;
    }
}

//input[type='file'] {
//  position: absolute;
//  width: 0;
//  height: 0;
//  padding: 0;
//  overflow: hidden;
//  border: 0;
//}

.upload-label {
    display: flex;
    border: 1px solid #d3d9e4;
    width: 160px;
    height: 40px;
    border-radius: 8px;
    align-items: center;
    justify-content: center;

    & > div {
        font-weight: 700;
        font-size: 13px;
    }
}

.prescriptionLink-a {
    color: #567fe7;
    font-weight: 400;
    font-size: 13px;
    padding-left: 10px;
    text-decoration: none;
    cursor: pointer;
}

.custom-arrow-icon {
    color: #fff;
}

.custom-close-icon {
    width: 30px;
    height: 30px;
}

/* 파일이 없는 경우 */
.empty-file-container-div {
    margin-top: 48px;

    & > table {
        margin-top: 8px;
        border-top: 2px solid #c8d6f8;
        border-bottom: 1px solid #c8d6f8;
        width: 100%;
        height: 50px;

        td:first-child {
            background: #f4f6fb;
            width: 10%;
            font-weight: 700;
            text-align: center;
            font-size: 13px;
        }

        td:nth-child(2) {
            padding: 10px;
            font-size: 12px;
        }
    }
}

.buttons-div {
    padding-top: 10px;
    display: flex;
    justify-content: flex-end;
}

.button-inside-div {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: center;
}
