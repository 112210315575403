//공통
.container {
  margin-left: 24px;
  margin-right: 24px;
}

//의사 등록
.doctor-register-container {
  display: flex;
  justify-content: center;
}

.doctor-basic-container {
  margin-left: 24px;
  margin-right: 24px;

  & > div:first-child {
    display: flex;
    justify-content: flex-start;
  }
}

.doctor-service-container {
  display: flex;
  flex-direction: column;
  margin-left: 15px;
}

.doctor-theme-container {
  padding-top: 24px;

  & > div:first-child {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 8px;
    margin-left: 10px;
  }
}

.doctor-license-container {
  margin-left: 24px;
  margin-right: 24px;

  & > div:first-child {
    display: flex;
    justify-content: flex-start;
  }
}

.doctor-time-table {
  margin-left: 24px;
  margin-right: 24px;
  padding-top: 16px;
}

//이미지
.image-container {
  padding-left: 50px;
}

//버튼
.button-container {
  display: flex;
  justify-content: center;
  padding-top: 44px;
  padding-bottom: 44px;
}

