:global .logo {
  width: 58px;
  height: 58px;
  margin-right: 42px;
}

:global .custom-download-icon {
  color: #1C6FC9;
  width: 24px;
  height: 16px;
  padding-right: 8px;
}

:global .custom-cloud-icon {
  color: #1C6FC9;
  width: 24px;
  height: 16px;
  padding-right: 8px;
}

:global .custom-cancel-icon {
  color: #C4C4C4;
  width: 16px;
  height: 16px;
}

:global .custom-pdf-icon {
  width: 24px;
  height: 24px;
  color: gray;
}

:global .family-badge {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 72px;
  height: 20px;
  background: #4CA555;
}